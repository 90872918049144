
import { mapGetters } from 'vuex'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination])
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            checkInterest: false,
        }
    },
    computed: {
        ...mapGetters({
            featuredContent1Getter: 'cms/featuredContent1',
		}),
		featuredContent1() {
			return this.featuredContent1Getter(this.$config.THEME)
		}
    },
    methods: {
        onIntersect(entries, observer) {
            const interesting = entries[0]?.isIntersecting
            if (interesting) {
                this.checkInterest = true
            }
        },
        onNext() {
            const elParent =
                document.getElementsByClassName('w-featured1-slider')
            if (!elParent || !elParent[0]) return
            const elNext =
                elParent[0].getElementsByClassName('swiper-button-next')
            if (!elNext || !elNext[0]) return
            elNext[0].dispatchEvent(new MouseEvent('click'))
        },
    },
}
